@charset "utf-8";

@import "../../node_modules/bulma/bulma.sass";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "./Scrollbar.scss";

html,body {
  height: 100%;
  overflow: hidden;
}

#root {
  height: 98%;
}

.thin-br {
  display: block; 
  content: ""; 
  margin-top: 34px; 
}
